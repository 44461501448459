import { Inject, Injectable } from "@angular/core";
import { NiceToastService } from "@recursyve/nice-ui-kit.v2";
import { Observable } from "rxjs";
import { Manager } from "socket.io-client";
import { AclGateway } from "../../acl.gateway";
import { Tickets } from "../models/tickets.model";
import { TicketEventTypes } from "./event-types/ticket.event-type";

@Injectable()
export class TicketGateway extends AclGateway {
    constructor(@Inject(Manager) manager: Manager, niceToastService: NiceToastService) {
        super("ticket", manager, niceToastService);
    }

    public get todayTicketsCreated$(): Observable<Tickets[]> {
        return this.listenForEvent(TicketEventTypes.TodayTicketCreated);
    }

    public get todayTicketsUpdated$(): Observable<Tickets[]> {
        return this.listenForEvent(TicketEventTypes.TodayTicketUpdated);
    }

    public get ticketsUpdated$(): Observable<Tickets[]> {
        return this.listenForEvent(TicketEventTypes.TicketsUpdated);
    }

    public get todayTicketRankUpdated$(): Observable<Tickets[]> {
        return this.listenForEvent(TicketEventTypes.TodayTicketRankUpdated);
    }

    public get todayTicketsDeleted$(): Observable<number[]> {
        return this.listenForEvent(TicketEventTypes.TodayTicketDeleted);
    }
}
