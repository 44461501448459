import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { NiceMediaWatcherService, NiceNavigationService } from "@recursyve/nice-ui-kit.v2";
import { delay } from "rxjs/operators";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";

@Component({
    selector: "acl-navbar",
    templateUrl: "navbar.template.html",
    styleUrls: ["navbar.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit {
    public isMobile = false;

    constructor(private breakpointObserver: BreakpointObserver, private navigationService: NiceNavigationService) {}

    public ngOnInit(): void {
        this.breakpointObserver.observe(["(max-width: 1380px)"]).pipe(delay(100)).subscribe((result: BreakpointState) => {
            if (result.matches) {
                this.isMobile = true;
                this.navigationService.getComponent("navbar")?.close();
            } else {
                this.isMobile = false;
                this.navigationService.getComponent("navbar")?.open();
            }
        });
    }
}
