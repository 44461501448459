import { Routes } from "@angular/router";
import { RolesEnum } from "../../api/acl/account/models/enums/roles.enum";
import { TicketType } from "../../api/common/enums/ticket-type.enum";
import { AccessGuard } from "../../guards/access.guard";

export const DASHBOARD_ROUTES: Routes = [
    { path: "", redirectTo: "ticket", pathMatch: "full" },
    {
        path: "bin",
        loadChildren: () => import("./bins/bins.module").then(m => m.BinsModule)
    },
    {
        path: "ticket",
        loadChildren: () => import("./tickets/tickets.module").then(m => m.TicketsModule),
        canMatch: [AccessGuard],
        data: {
            ticketType: TicketType.Batch,
            access: {
                fallbackTo: "/dashboard/shipping-order",
                roles: [RolesEnum.Admin, RolesEnum.Batch, RolesEnum.Shipping, RolesEnum.Counter]
            }
        }
    },
    {
        path: "shipping-order",
        loadChildren: () => import("./tickets/tickets.module").then(m => m.TicketsModule),
        data: {
            ticketType: TicketType.ShippingOrder
        }
    },
    {
        path: "spreader",
        loadChildren: () => import("./equipments/equipments.module").then(m => m.EquipmentsModule)
    },
    {
        path: "grain-box",
        loadChildren: () => import("./equipments/equipments.module").then(m => m.EquipmentsModule)
    },
    {
        path: "nurse",
        loadChildren: () => import("./equipments/equipments.module").then(m => m.EquipmentsModule)
    },
    {
        path: "vapam",
        loadChildren: () => import("./equipments/equipments.module").then(m => m.EquipmentsModule)
    }
];
