import { NiceNavigationItem } from "@recursyve/nice-ui-kit.v2";

export const shippingOrderUserNavigation: NiceNavigationItem[] = [
    {
        id: "home",
        type: "group",
        children: [
            {
                id: "shipping_order_tickets",
                title: "navigation.shipping_order_tickets.title",
                type: "collapsable",
                iconType: "fontawesome",
                icon: "fa-duotone fa-dolly text-primary",
                children: [
                    {
                        id: "dashboard",
                        title: "navigation.shipping_order_tickets.shipping_orders",
                        type: "basic",
                        iconType: "fontawesome",
                        icon: "fa-duotone fa-bars-staggered",
                        link: "/dashboard/shipping-order"
                    }
                ]
            }
        ]
    }
];
