import { Injectable } from "@angular/core";
import { NiceNavigationService } from "@recursyve/nice-ui-kit.v2";
import { RolesEnum } from "../api/acl/account/models/enums/roles.enum";
import { adminNavigation } from "./admin.navigation";
import { batchUserNavigation } from "./batch-user.navigation";
import { NavigationType } from "./navigation.type";
import { shippingOrderUserNavigation } from "./shipping-order-user.navigation";
import { shippingUserNavigation } from "./shipping-user.navigation";

@Injectable({
    providedIn: "root"
})
export class NavigationService {
    constructor(private niceNavigationService: NiceNavigationService) {}

    public async init() {
        this.niceNavigationService.storeNavigation(NavigationType.Admin, adminNavigation);
        this.niceNavigationService.storeNavigation(NavigationType.BatchUser, batchUserNavigation);
        this.niceNavigationService.storeNavigation(NavigationType.ShippingUser, shippingUserNavigation);
        this.niceNavigationService.storeNavigation(NavigationType.ShippingOrderUser, shippingOrderUserNavigation);
    }

    public setNavigation(role: RolesEnum): void {
        if ([RolesEnum.Admin, RolesEnum.Counter].includes(role)) {
            this.niceNavigationService.setCurrentNavigation(NavigationType.Admin);
            return;
        }

        if ([RolesEnum.Preparator, RolesEnum.Approver].includes(role)) {
            this.niceNavigationService.setCurrentNavigation(NavigationType.ShippingOrderUser);
            return;
        }

        if (RolesEnum.Shipping === role) {
            this.niceNavigationService.setCurrentNavigation(NavigationType.ShippingUser);
            return;
        }

        this.niceNavigationService.setCurrentNavigation(NavigationType.BatchUser);
    }
}
