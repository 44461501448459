import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpStatusCodes } from "@recursyve/nice-ui-kit.v2";
import { catchError, map, Observable, of, throwError } from "rxjs";
import { _BaseApi } from "../../../base.api";
import { ValidateUniqueLotDto } from "../dto/validate-unique-lot.dto";

@Injectable()
export class GestAgroLotsLotsApi extends _BaseApi {
    constructor(private http: HttpClient) {
        super("gestagro-lot", http);
    }

    public validateUniqueLot(dto: ValidateUniqueLotDto): Observable<boolean> {
        return this.http
            .head(this.url(""), {
                params: { ...dto }
            })
            .pipe(
                map(() => true),
                catchError(error => {
                    if (error?.status === HttpStatusCodes.CONFLICT) {
                        return of(false);
                    }
                    return throwError(() => new Error(error));
                })
            );
    }
}
