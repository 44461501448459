import { Injectable } from "@angular/core";
import { AccountApi } from "./acl/account/account.api";
import { AssetsApi } from "./acl/assets/apis/assets.api";
import { AuthApi } from "./acl/auth/apis/auth.api";
import { BinApi } from "./acl/bin/bin.api";
import { ClientApi } from "./acl/client/client.api";
import { ConstantsApi } from "./acl/constants/constants.api";
import { EquipmentStatusHistoriesApi } from "./acl/equipments-status-histories/apis/equipment-status-histories.api";
import { EquipmentsApi } from "./acl/equipments/apis/equipments.api";
import { GrainBoxCapacitiesApi } from "./acl/equipments/apis/grain-box-capacities.api";
import { GrainBoxRollingTypesApi } from "./acl/equipments/apis/grain-box-rolling-types.api";
import { GrainBoxSizesApi } from "./acl/equipments/apis/grain-box-sizes.api";
import { GrainBoxWheelsApi } from "./acl/equipments/apis/grain-box-wheels.api";
import { NurseWheelsApi } from "./acl/equipments/apis/nurse-wheels.api";
import { OtherEquipmentNamesApi } from "./acl/equipments/apis/other-equipment-names.api";
import { SpreaderHeightsApi } from "./acl/equipments/apis/spreader-heights.api";
import { SpreaderWheelsApi } from "./acl/equipments/apis/spreader-wheels.api";
import { SpreaderWidthsApi } from "./acl/equipments/apis/spreader-widths.api";
import { FormulaApi } from "./acl/formula/formula.api";
import { GestAgroLotsLotsApi } from "./acl/lots/apis/gest-agro-lots.api";
import { LotsApi } from "./acl/lots/apis/lots.api";
import { MaterialsApi } from "./acl/materials/apis/materials.api";
import { MixerApi } from "./acl/mixers/mixer.api";
import { ShippingOrderItemsApi } from "./acl/shipping-orders/apis/shipping-order-items.api";
import { TicketApi } from "./acl/ticket/ticket.api";
import { WarehousesApi } from "./acl/warehouses/apis/warehouses.api";
import { InfoApi } from "./info/info.api";

@Injectable({
    providedIn: "root"
})
export class ApiService {
    constructor(
        public readonly account: AccountApi,
        public readonly asset: AssetsApi,
        public readonly auth: AuthApi,
        public readonly bin: BinApi,
        public readonly client: ClientApi,
        public readonly constant: ConstantsApi,
        public readonly equipment: EquipmentsApi,
        public readonly equipmentStatusHistory: EquipmentStatusHistoriesApi,
        public readonly formula: FormulaApi,
        public readonly gestAgroLotsLotsApi: GestAgroLotsLotsApi,
        public readonly grainBoxCapacities: GrainBoxCapacitiesApi,
        public readonly grainBoxRollingTypes: GrainBoxRollingTypesApi,
        public readonly grainBoxSizes: GrainBoxSizesApi,
        public readonly grainBoxWheels: GrainBoxWheelsApi,
        public readonly info: InfoApi,
        public readonly lots: LotsApi,
        public readonly materials: MaterialsApi,
        public readonly mixer: MixerApi,
        public readonly nurseWheels: NurseWheelsApi,
        public readonly otherEquipmentNames: OtherEquipmentNamesApi,
        public readonly shippingOrderItems: ShippingOrderItemsApi,
        public readonly spreaderHeights: SpreaderHeightsApi,
        public readonly spreaderWheels: SpreaderWheelsApi,
        public readonly spreaderWidths: SpreaderWidthsApi,
        public readonly ticket: TicketApi,
        public readonly warehouses: WarehousesApi
    ) {}
}
