import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { _BaseApi } from "../../../base.api";
import { Warehouses } from "../models/warehouses.model";

@Injectable()
export class WarehousesApi extends _BaseApi {
    constructor(private http: HttpClient) {
        super("warehouse", http);
    }

    public getAll(): Observable<Warehouses[]> {
        return this.get("", { type: Warehouses });
    }
}
