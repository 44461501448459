import { DatabaseEntities } from "../../../common/models/database-entities.model";
import { MaterialTypes } from "../enums/material-types.enum";

export class Materials extends DatabaseEntities<Materials> {
    gestagroId?: string;
    description?: string;
    type?: MaterialTypes;
    factClassItem?: string;
    factLocncode?: string;
    isArchived: boolean;
    archivedAt: Date;
}
