import { Injectable } from "@angular/core";
import { CanMatch, Route, Router, UrlTree } from "@angular/router";
import { filterNilValue } from "@datorama/akita";
import { from, Observable } from "rxjs";
import { map, skipWhile } from "rxjs/operators";
import { RolesEnum } from "../api/acl/account/models/enums/roles.enum";
import { AppQuery } from "../store/app.query";
import { AppService } from "../store/app.service";

export interface AccessGuardData {
    fallbackTo?: string;
    roles: RolesEnum[];
}

@Injectable({ providedIn: "root" })
export class AccessGuard implements CanMatch {
    constructor(private router: Router, private appQuery: AppQuery, private appService: AppService) {}

    public canMatch(route: Route): Observable<boolean | UrlTree> {
        return this.canAccess(route.data.access);
    }

    public canAccess({ roles, fallbackTo }: AccessGuardData): Observable<boolean | UrlTree> {
        return this.appQuery.selectCurrentRole().pipe(
            skipWhile(account => {
                if (account) {
                    return false;
                }

                from(this.appService.loadCurrentUser()).subscribe();
                return true;
            }),
            filterNilValue(),
            map(role => {
                const hasAccess = roles.includes(role);
                if (hasAccess) {
                    return true;
                }

                if (!fallbackTo) {
                    return false;
                }

                return this.router.createUrlTree([fallbackTo]);
            })
        );
    }
}
