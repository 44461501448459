import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AccountApi } from "./acl/account/account.api";
import { AssetsApi } from "./acl/assets/apis/assets.api";
import { AuthApi } from "./acl/auth/apis/auth.api";
import { BinApi } from "./acl/bin/bin.api";
import { BinGateway } from "./acl/bin/gateways/bin.gateway";
import { ClientApi } from "./acl/client/client.api";
import { ConstantsApi } from "./acl/constants/constants.api";
import { EquipmentStatusHistoriesApi } from "./acl/equipments-status-histories/apis/equipment-status-histories.api";
import { EquipmentsApi } from "./acl/equipments/apis/equipments.api";
import { GrainBoxCapacitiesApi } from "./acl/equipments/apis/grain-box-capacities.api";
import { GrainBoxRollingTypesApi } from "./acl/equipments/apis/grain-box-rolling-types.api";
import { GrainBoxSizesApi } from "./acl/equipments/apis/grain-box-sizes.api";
import { GrainBoxWheelsApi } from "./acl/equipments/apis/grain-box-wheels.api";
import { NurseWheelsApi } from "./acl/equipments/apis/nurse-wheels.api";
import { OtherEquipmentNamesApi } from "./acl/equipments/apis/other-equipment-names.api";
import { SpreaderHeightsApi } from "./acl/equipments/apis/spreader-heights.api";
import { SpreaderWheelsApi } from "./acl/equipments/apis/spreader-wheels.api";
import { SpreaderWidthsApi } from "./acl/equipments/apis/spreader-widths.api";
import { EquipmentsGateway } from "./acl/equipments/gateway/equipments.gateway";
import { FormulaApi } from "./acl/formula/formula.api";
import { GestAgroLotsLotsApi } from "./acl/lots/apis/gest-agro-lots.api";
import { LotsApi } from "./acl/lots/apis/lots.api";
import { MaterialsApi } from "./acl/materials/apis/materials.api";
import { MixerGateway } from "./acl/mixers/gateways/mixer.gateway";
import { MixerApi } from "./acl/mixers/mixer.api";
import { ShippingOrderItemsApi } from "./acl/shipping-orders/apis/shipping-order-items.api";
import { TicketGateway } from "./acl/ticket/gateways/ticket.gateway";
import { TicketApi } from "./acl/ticket/ticket.api";
import { WarehousesApi } from "./acl/warehouses/apis/warehouses.api";
import { InfoApi } from "./info/info.api";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { ErrorInterceptor } from "./interceptors/error.interceptor";

@NgModule({
    providers: [
        AccountApi,
        AssetsApi,
        AuthApi,
        BinApi,
        BinGateway,
        ClientApi,
        ConstantsApi,
        EquipmentStatusHistoriesApi,
        EquipmentsApi,
        EquipmentsGateway,
        FormulaApi,
        GestAgroLotsLotsApi,
        GrainBoxCapacitiesApi,
        GrainBoxRollingTypesApi,
        GrainBoxSizesApi,
        GrainBoxWheelsApi,
        InfoApi,
        LotsApi,
        MaterialsApi,
        MixerApi,
        MixerGateway,
        NurseWheelsApi,
        OtherEquipmentNamesApi,
        ShippingOrderItemsApi,
        SpreaderHeightsApi,
        SpreaderWheelsApi,
        SpreaderWidthsApi,
        TicketApi,
        TicketGateway,
        WarehousesApi,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ]
})
export class ApiModule {}
