import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { _BaseApi } from "../../../base.api";
import { FindLotsDto } from "../dto/find-lot.dto";
import { Lots } from "../models/lots.model";

@Injectable()
export class LotsApi extends _BaseApi {
    constructor(private http: HttpClient) {
        super("lot", http);
    }

    public getById(id: number): Observable<Lots> {
        return this.get(`${id}`, { type: Lots });
    }

    public getAll(dto: FindLotsDto): Observable<Lots[]> {
        return this.get("", { params: { ...dto }, type: Lots });
    }
}
