import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { _BaseApi } from "../../../base.api";
import { ShippingOrderItemsEquipementsAssociationDto } from "../dto/shipping-order-items-equipments-association.dto";
import { UpsertShippingOrderItemLotsDto } from "../dto/upsert-shipping-order-item-lots.dto";
import { ShippingOrderItems } from "../models/shipping-order-items.model";

@Injectable()
export class ShippingOrderItemsApi extends _BaseApi {
    constructor(private http: HttpClient) {
        super("shipping-order-item", http);
    }

    public getById(id: number): Observable<ShippingOrderItems> {
        return this.get<ShippingOrderItems>(`${id}`);
    }

    public upsertShippingOrderItemLots(
        id: number,
        dto: UpsertShippingOrderItemLotsDto
    ): Observable<ShippingOrderItems> {
        return this.put<ShippingOrderItems>(`${id}/lots`, dto);
    }

    public updateShippingOrderItemEquipmentAssociation(
        id: number,
        dto: ShippingOrderItemsEquipementsAssociationDto
    ): Observable<ShippingOrderItems> {
        return this.put<ShippingOrderItems>(`${id}/equipment-id`, dto);
    }

    public unsetShippingOrderItemEquipmentAssociation(id: number): Observable<void> {
        return this.delete<void>(`${id}/equipment-id`);
    }
}
